import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { RiUserFill } from "react-icons/ri";
import { BiLogOut } from "react-icons/bi";
import { PiStudentBold } from "react-icons/pi";
import { BsCheckCircle } from "react-icons/bs";
import { AiOutlineForm } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { BsBell } from "react-icons/bs";
import { AiFillSetting } from "react-icons/ai";
import { MdCancel, MdPending } from "react-icons/md";
import Logo from "../assets/images/cropped-ronald-partner-logo.png";
import { State } from "../context/AuthProvider";

const Sidebar = ({ toggle, handleChange, handleClick }) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  // const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const { user, setToken, setUser } = State();

  const logOut = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem("token");
  };


  

  return (
    <>
      <header class={toggle ? "main-header active" : "main-header"}>
        <nav class="navbar">
          <div className="logo-container">
            <img src={Logo} alt="Ronald logo" className="logo" />
            {/* <div className="sidebar-button">
          <i onClick={handleClick}>
            <MdCancel />
          </i>
        </div> */}
          </div>
          <div>
            <ul>
              {user?.is_admin ? (
                <>
                  <li class="nav-link">
                    <Link to="/" class={splitLocation[1] === "" ? "act" : ""}>
                      <i>
                        <FaHome />
                      </i>
                      Dashboard
                    </Link>
                  </li>
                  <li class="nav-link">
                    <Link
                      to="/accept"
                      class={splitLocation[1] === "accept" ? "act" : ""}
                    >
                      <i>
                        <BsCheckCircle />
                      </i>
                      Accepted
                    </Link>
                  </li>
                  <li class="nav-link">
                    <Link
                      to="/pending"
                      class={splitLocation[1] === "pending" ? "act" : ""}
                    >
                      <i>
                        <MdPending />
                      </i>
                      Pending
                    </Link>
                  </li>
                  <li class="nav-link">
                    <Link
                      to="/reject"
                      class={splitLocation[1] === "reject" ? "act" : ""}
                    >
                      <i>
                        <MdCancel />
                      </i>
                      Rework
                    </Link>
                  </li>
                  <li class="nav-link">
                    <Link
                      to="/applicants"
                      class={splitLocation[1] === "applicants" ? "act" : ""}
                    >
                      <i>
                        <FaUsers />
                      </i>
                      Applicants
                    </Link>
                  </li>
                  <li onClick={logOut} class="log_out">
                    <Link>
                      <i>
                        <BiLogOut />
                      </i>
                      Logout
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li class="nav-link">
                    <Link
                      to="/student"
                      class={splitLocation[1] === "student" ? "act" : ""}
                    >
                      <i>
                        <PiStudentBold />
                      </i>
                      Student Dash
                    </Link>
                  </li>
                  <li class="nav-link">
                    <Link
                      to="/profile"
                      class={splitLocation[1] === "profile" ? "act" : ""}
                    >
                      <i>
                        <RiUserFill />
                      </i>
                      Profile
                    </Link>
                  </li>
                  <li class="nav-link">
                    <Link
                      to="/apply"
                      class={splitLocation[1] === "apply" ? "act" : ""}
                    >
                      <i>
                        <AiOutlineForm />
                      </i>
                      Apply
                    </Link>
                  </li>
                  {/* <li class="nav-link">
                <Link to="#" onClick={handleChange} class={splitLocation[1] === "#" ? "act" : ""}>
                  <i>
                    <BsBell />
                  </i>
                  Notifications
                </Link>
              </li> */}
                  <li class="nav-link">
                    <Link
                      to="/setting"
                      class={splitLocation[1] === "setting" ? "act" : ""}
                    >
                      <i>
                        <AiFillSetting />
                      </i>
                      Setting
                    </Link>
                  </li>
                  <li onClick={logOut} class="log_out">
                    <Link>
                      <i>
                        <BiLogOut />
                      </i>
                      Logout
                    </Link>
                  </li>
                </>
              )}
            </ul>
            {/* <button className="close-button" onClick={toggleSidebar}>
            <MdCancel />
          </button> */}
          </div>
         
        </nav>
      </header>
      {/* End of header (component)*/}
    </>
  );
};

export default Sidebar;
