import { useRef, useState, useEffect } from "react";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import axios from "../../API/axios";
import "../../components/auths/Register.css";
import { State } from "../../context/AuthProvider";
import Logo from "../../assets/images/logo.png";
import useAxios from "../hooks/useAxios";
import { HiMenu } from "react-icons/hi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import LogoBlack from "../../assets/images/cropped-ronald-partner-logo.png";
import Loader from "../Loader";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = "/signup";

const Register = () => {
  const nameRef = useRef();
  const emailRef = useRef();
  const errRef = useRef();
  const { setToken } = State();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [validName, setValidName] = useState(false);
  const [nameFocus, setNameFocus] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleClick = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    nameRef.current.focus();
  }, []);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [name, email, pwd, matchPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const v1 = PWD_REGEX.test(pwd);
    if (!v1) {
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      const response = await axios.post(
        REGISTER_URL,
        JSON.stringify({
          name,
          email,
          password: pwd,
          confirm_password: matchPwd,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      console.log(JSON.stringify(response?.data));
      //setSuccess(true);
      const accessToken = response?.data?.user_token;
      setToken(accessToken);
      localStorage.setItem("token", JSON.stringify(accessToken));
      setName("");
      setEmail("");
      setPwd("");
      setMatchPwd("");
      navigate("/");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 409) {
        setErrMsg("Username Taken");
      } else {
        setErrMsg("Registration Failed");
      }
      setLoading(false);
      errRef.current.focus();
    }
  };

  return (
    <div>
      <div>
        <header className="header" data-header>
          <div className="container">
            <a href="#" className="logo">
              <img
                src={Logo}
                width={124}
                height={24}
                alt="Ronald home"
                className="logo-light"
              />
              <img
                src="../../assets/images/cropped-ronald-partner-logo.png"
                width={124}
                height={24}
                alt="Adex home"
                className="logo-dark"
              />
            </a>
            <nav className={`navbarr ${isNavOpen ? "active" : ""}`} data-navbar>
              <div className="navbarr-top">
                <a href="#" className="logo">
                  <img src={Logo} width={74} height={24} alt="Ronald home" />
                </a>

                <i className="nav-close-btn" onClick={handleClick}>
                  <AiOutlineCloseCircle />
                </i>
              </div>
              <ul className={`navbarr-list ${isNavOpen ? "show" : ""}`}>
                <li>
                  <a
                    href="https://ronald-partners.net"
                    className="navbarr-link"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="https://ronald-partners.net/pages/about-us.html"
                    className="navbarr-link"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="https://ronald-partners.net/pages/our-services.html"
                    className="navbarr-link"
                  >
                    Our services
                  </a>
                </li>
                <li>
                  <a
                    href="https://ronald-partners.net/pages/offers.html"
                    className="navbarr-link"
                  >
                    Offers
                  </a>
                </li>
                <li>
                  <a
                    href="https://ronald-partners.net/pages/contact-us.html"
                    className="navbarr-link"
                  >
                    Contact
                  </a>
                </li>
              </ul>
              <div className="wrapper">
                <li>
                  <a href="tel:001234567890" className="contact-link">
                    Phone: +234(1)2953655
                  </a>
                </li>
              </div>
              <ul className="social-list">
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-facebook" />
                  </a>
                </li>
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-dribbble" />
                  </a>
                </li>
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-instagram" />
                  </a>
                </li>
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-youtube" />
                  </a>
                </li>
              </ul>
            </nav>

            <i className="nav-open-btn" onClick={handleClick}>
              <HiMenu />
            </i>
            <div className="overlay" data-nav-toggler data-overlay />
          </div>
        </header>
        <div className="form-contact">
          <div className="study-form-logo">
            <img
              src="../../assets/images/cropped-ronald-partner-logo.png"
              alt="Logo"
            />
          </div>
          <p className="address">
            Head Office: Ronald and Partners Consult, 1st Floor, Nurses House,
            PC 43, Church Gate Street, Victoria Island, Lagos State.
          </p>
          <p className="contact">
            Contact Mobile Numbers: +234(1)2953655, +2348034311748,
            +2349073666847
          </p>
        </div>
        <h3 className="form-heading">
          Signup to apply and track your application.
        </h3>

        {loading && <Loader />}

        {success ? (
          <section>
            <h1>Success!</h1>
            <p>
              <a href="#">Sign In</a>
            </p>
          </section>
        ) : (
          <div className="form-flex">
            <div className="container-form">
              <section>
                <p
                  ref={errRef}
                  className={errMsg ? "errmsg" : "offscreen"}
                  aria-live="assertive"
                >
                  {errMsg}
                </p>

                <div className="logo-auth">
                  <h1>Register</h1>
                  <img src={LogoBlack} alt="Ronald logo" className="logo" />
                </div>
                <form onSubmit={handleSubmit}>
                  <label htmlFor="name">Name:</label>
                  <input
                    type="text"
                    id="name"
                    ref={nameRef}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required
                  />

                  <label htmlFor="email">Email:</label>
                  <input
                    type="text"
                    id="email"
                    ref={emailRef}
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                  />

                  <label htmlFor="password">
                    Password:
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={validPwd ? "valid" : "hide"}
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={validPwd || !pwd ? "hide" : "invalid"}
                    />
                  </label>
                  <input
                    type="password"
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                    aria-invalid={validPwd ? "false" : "true"}
                    aria-describedby="pwdnote"
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => setPwdFocus(false)}
                  />
                  <p
                    id="pwdnote"
                    className={
                      pwdFocus && !validPwd ? "instructions" : "offscreen"
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                    8 to 24 characters.
                    <br />
                    Must include uppercase and lowercase letters, a number and a
                    special character.
                    <br />
                    Allowed special characters:{" "}
                    <span aria-label="exclamation mark">!</span>{" "}
                    <span aria-label="at symbol">@</span>{" "}
                    <span aria-label="hashtag">#</span>{" "}
                    <span aria-label="dollar sign">$</span>{" "}
                    <span aria-label="percent">%</span>
                  </p>

                  <label htmlFor="confirm_pwd">
                    Confirm Password:
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={validMatch && matchPwd ? "valid" : "hide"}
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={validMatch || !matchPwd ? "hide" : "invalid"}
                    />
                  </label>
                  <input
                    type="password"
                    id="confirm_pwd"
                    onChange={(e) => setMatchPwd(e.target.value)}
                    value={matchPwd}
                    required
                    aria-invalid={validMatch ? "false" : "true"}
                    aria-describedby="confirmnote"
                    onFocus={() => setMatchFocus(true)}
                    onBlur={() => setMatchFocus(false)}
                  />
                  <p
                    id="confirmnote"
                    className={
                      matchFocus && !validMatch ? "instructions" : "offscreen"
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                    Must match the first password input field.
                  </p>

                  <button
                    className="button"
                    disabled={!validPwd || !validMatch}
                  >
                    Sign Up
                  </button>
                </form>
                <p>
                  Already registered?
                  <br />
                  <span className="line">
                    {/*put router link here*/}
                    <a href="/login">Sign In</a>
                  </span>
                </p>
              </section>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Register;
