import React, { useState } from "react";
import Sidebar from "../sidebar";
// import Nav from "../nav";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AiOutlinePoweroff } from "react-icons/ai";
import "./profile.css";
import { State } from "../../context/AuthProvider";
import axios from "../../API/axios";
import StudentNav from "../studentnav";

const Setting = () => {
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => setToggle(!toggle);
  const [change, setChange] = useState(false);
  const handleChange = () => setChange(!change);

  const { user, token, setToken, setUser } = State();

  const [formData, setFormData] = useState({
    name: user.name,
    email: user.email,
  });

  const [passwordFormData, setPasswordFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const UPDATE_PROFILE_URL = `/profile/${user._id}`;

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePasswordFormChange = (e) => {
    setPasswordFormData({
      ...passwordFormData,
      [e.target.name]: e.target.value,
    });
  };

  const logOut = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem("token");
  };

  const updateProfile = async (e) => {
    try {
      const response = await axios.patch(UPDATE_PROFILE_URL, formData, {
        headers: {
          "Content-Type": "application/json",
          authorization: `${token}`,
        },
        withCredentials: true,
      });
      const accessToken = response?.data?.user_token;
      // const roles = response?.data?.roles;
      setToken(accessToken);
      localStorage.setItem("token", JSON.stringify(accessToken));
      //console.log(response)
    } catch (error) {
      console.log(error);
    }

    // Using sweetalert
    const MySwal = withReactContent(Swal);

    MySwal.fire("Good job!", "You updated your information successfully!");
  };

  const updatePassword = async (e) => {
    try {
      if (
        passwordFormData.newPassword === passwordFormData.confirmNewPassword
      ) {
        const response = await axios.patch(
          UPDATE_PROFILE_URL,
          passwordFormData,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `${token}`,
            },
            withCredentials: true,
          }
        );
        const accessToken = response?.data?.user_token;
        // const roles = response?.data?.roles;
        setToken(accessToken);
        localStorage.setItem("token", JSON.stringify(accessToken));
        //console.log(response)
      } else {
        console.log("password mismatch");
      }
    } catch (error) {
      console.log(error);
    }

    // Using sweetalert
    const MySwal = withReactContent(Swal);

    MySwal.fire("Good job!", "You updated your password successfully!");
  };

  return (
    <>
      <Sidebar toggle={toggle} handleChange={() => handleChange()} />
      {/* End of header (component)*/}
      <main class="main-doc">
        <StudentNav
          change={change}
          handleChange={() => handleChange()}
          handleClick={() => handleToggle()}
        />
        {/* End of navigation (component) */}

        <div class="main-profile">
          <h3 class="i_name">Profile</h3>
          <div class="profile_cont">
            <div class="profile_head">
              <h4>Personal Information</h4>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Name</p>
                <input
                  name="name"
                  type="text"
                  onChange={handleFormChange}
                  value={formData.name}
                />
              </div>
              <div class="input_box">
                <p>Email</p>
                <input
                  name="email"
                  type="text"
                  onChange={handleFormChange}
                  value={formData.email}
                />
              </div>
            </div>
            <div class="per_button">
              <button onClick={updateProfile}>Update</button>
            </div>
          </div>
          <div class="profile_cont">
            <div class="profile_head">
              <h4>Update Password</h4>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Old Password</p>
                <input
                  name="oldPassword"
                  type="password"
                  onChange={handlePasswordFormChange}
                />
              </div>
              <div class="input_box">
                <p>New Password</p>
                <input
                  name="newPassword"
                  type="password"
                  onChange={handlePasswordFormChange}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Confirm New Password</p>
                <input
                  name="confirmNewPassword"
                  type="password"
                  onChange={handlePasswordFormChange}
                />
              </div>
            </div>
            <div class="per_button">
              <button onClick={updatePassword}>Update</button>
            </div>
          </div>
          <div onClick={logOut} class="setting_close">
            <i>
              <AiOutlinePoweroff />
            </i>
          </div>
          <div onClick={logOut} class="setting_close">
            <p>Logout</p>
          </div>
        </div>
      </main>
    </>
  );
};

export default Setting;
