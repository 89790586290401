import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../sidebar";
import Nav from "../nav";
import UserModal from "../usermodal";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./dashboard.css";
import { State } from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import axios from "../../API/axios";
import NavAdmin from "../navAdmin";
//import loadingAnimation from "../../assets/images/Rolling.svg"

const Pending = () => {
  const ref = useRef();
  const { pendingFormData, token, setModalData } = State();
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const handleToggle = () => setToggle(!toggle);
  const [change, setChange] = useState(false);
  const handleChange = () => setChange(!change);
  const [open, setOpen] = useState(false);
  const handlesetInterface = () => setOpen(!open);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);

  const handleModalOpen = (data) => {
    handlesetInterface();
    setModalData(data);
  };

  const acceptApplication = async (id) => {
    try {
      const response = await axios.patch(
        `/form/${id}`,
        { status: "accepted" },
        {
          headers: { "Content-Type": "application/json", authorization: token },
          withCredentials: true,
        }
      );
      navigate("/");
    } catch (error) {
      console.log(error);
    }
    const MySwal = withReactContent(Swal);

    MySwal.fire("Good job!", "Accepted successfully!");
  };
  const reworkApplication = async (id) => {
    try {
      const response = await axios.patch(
        `/form/${id}`,
        { status: "rework" },
        {
          headers: { "Content-Type": "application/json", authorization: token },
          withCredentials: true,
        }
      );
      navigate("/");
    } catch (error) {
      console.log(error);
    }
    const MySwal = withReactContent(Swal);

    MySwal.fire(
      "Good job!",
      "You successfully told the user to rework application!"
    );
  };

  return (
    <>
      <Sidebar toggle={toggle} handleChange={() => handleChange()} />
      {/* End of header (component)*/}
      <main class="main-doc">
        <NavAdmin
          change={change}
          handleChange={() => handleChange()}
          handleClick={() => handleToggle()}
        />
        {/* End of navigation (component) */}

        <div class="main-container">
          <div class="pen_head">
            <h3>Pending Applicants</h3>
          </div>

          <div class="board">
            <table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Form Type</td>
                  <td>Date</td>
                  <td>Action</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {pendingFormData.map((data) => {
                  const date = new Date(data.createdAt);
                  const yyyy = date.getFullYear();
                  const mm = date.getMonth();
                  const dd = date.getDate();

                  return (
                    <tr key={data._id}>
                      <td class="people">
                        <img
                          src={data.userId.profileImgUrl}
                          alt={data.userId.name}
                        />
                        <div class="people_des">
                          <h5 onClick={() => handleModalOpen(data)}>
                            {data.userId.name}
                          </h5>
                          <p onClick={() => handleModalOpen(data)}>
                            {data.userId.email}
                          </p>
                        </div>
                      </td>
                      <UserModal
                        open={open}
                        ref={ref}
                        closeModal={handlesetInterface}
                        data={data}
                      />
                      <td class="people_design">
                        <h5>{data.formType}</h5>
                      </td>
                      <td class="tab_active">
                        <p>{dd + "/" + mm + "/" + yyyy}</p>
                      </td>
                      <td class="tab_role">
                        <p onClick={() => acceptApplication(data._id)}>
                          Accept
                        </p>
                      </td>
                      <td class="tab_edit">
                        <p
                          onClick={() => reworkApplication(data._id)}
                          style={{ color: "white" }}
                        >
                          Rework
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </>
  );
};

export default Pending;
