import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../sidebar';
import UserModal from '../usermodal';
import './dashboard.css';
import { State } from '../../context/AuthProvider';
import NavAdmin from '../navAdmin';

const Applicants = () => {
  const ref = useRef();
  const {applyFormData,setModalData}=State()
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => setToggle(!toggle);
  const [change, setChange] = useState(false);
  const handleChange = () => setChange(!change);
  const [open, setOpen] = useState(false);
  const handlesetInterface = () => setOpen(!open);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [ref]);

  const handleModalOpen=(data)=>{
    handlesetInterface()
    setModalData(data)
  }

  return (
    <>
      <Sidebar 
        toggle={toggle}
        handleChange={() => handleChange()}
      />
      { /* End of header (component)*/ }
      <main class="main-doc">
       <NavAdmin
         change={change}
         handleChange={() => handleChange()}
         handleClick={() => handleToggle()}
      />

        { /* End of navigation (component) */}

        <div class="main-container">
          <div class='pen_head'>
            <h3>Accepted Applicants</h3>
          </div>
          <div class='board'>
            <table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Form Type</td>
                  <td>Date</td>
                  <td>Status</td>
                  {/* <td></td> */}
                </tr>
              </thead>
              <tbody>
                {
                  applyFormData.map((data)=>{
                    console.log(data)

                    const createdDate=new Date(data.createdAt)
                    const createdyyyy=createdDate.getFullYear()
                    const createdmm=createdDate.getMonth()
                    const createddd=createdDate.getDate()
                    return(
                      <tr>
                        <td class='people'>
                          <img src={data.userId.profileImgUrl} alt={data.userId.name} />
                          <div class='people_des'>
                            <h5 onClick={()=> handleModalOpen(data)}>{data.userId.name}</h5>
                            <p onClick={()=> handleModalOpen(data)}>{data.userId.email}</p>
                          </div>
                        </td>
                        <UserModal 
                          open={open}
                          ref={ref}
                          closeModal={handlesetInterface}
                          data={data}
                        />
                        <td class='people_design'>
                          <h5>{data.formType}</h5>
                        </td>

                        <td class='tab_active'>
                          <p>{createddd+"/"+createdmm+"/"+createdyyyy}</p>
                        </td>

                        <td class='tab_role'>
                          <p style={{textTransform:"capitalize"}}>{data.status}</p>
                        </td>

                        {/* <td class='tab_edit'>
                          <Link>Edit</Link>
                        </td> */}
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </>
  )
};

export default Applicants;
