import React, {useState, useEffect, useContext, useRef} from "react";
import "../../components/auths/TestLogin.css";
import "../../components/auths/Register.css";
import Logo from "../../assets/images/logo.png";
import { State } from "./../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import axios from "../../API/axios";
import { HiMenu } from "react-icons/hi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import LogoBlack from "../../assets/images/cropped-ronald-partner-logo.png";
import Loader from "../Loader";
// import AuthNavbar from "./AuthNavbar";
const LOGIN_URL = "/login";



const LoginForm = () => {
  const { setToken, user } = State();
  const [toggle, setToggle] = useState(false);
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = () => setChange(!change);
  const handleToggle = () => setToggle(!toggle);

  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [navigate]);

  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleClick = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [email, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ email, password: pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      console.log(JSON.stringify(response?.data));
      const accessToken = response?.data?.user_token;
      // const roles = response?.data?.roles;
      setToken(accessToken);
      localStorage.setItem("token", JSON.stringify(accessToken));
      setEmail("");
      setPwd("");
      // setSuccess(true);
      navigate("/");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Email or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      setLoading(false);
      errRef.current.focus();
    }
  };

  return (
    <div>
      <div>
        <header className="header" data-header>
          <div className="container">
            <a href="#" className="logo">
              <img
                src={Logo}
                width={124}
                height={24}
                alt="Ronald home"
                className="logo-light"
              />
              <img
                src={LogoBlack}
                width={124}
                height={24}
                alt="Adex home"
                className="logo-dark"
              />
            </a>
            <nav className={`navbarr ${isNavOpen ? "active" : ""}`} data-navbar>
              <div className="navbarr-top">
                <a href="#" className="logo">
                  <img
                    src={Logo}
                    width={74}
                    height={24}
                    alt="Ronald home"
                  />
                </a>
                <i className="nav-close-btn" onClick={handleClick}>
                  <AiOutlineCloseCircle />
                </i>
              </div>
              <ul className={`navbarr-list ${isNavOpen ? "show" : ""}`}>
                <li>
                  <a href="https://ronald-partners.net" className="navbarr-link">
                    Home
                  </a>
                </li>
                <li>
                  <a href="https://ronald-partners.net/pages/about-us.html" className="navbarr-link">
                    About
                  </a>
                </li>
                <li>
                  <a href="https://ronald-partners.net/pages/our-services.html" className="navbarr-link">
                    Our services
                  </a>
                </li>
                <li>
                  <a href="https://ronald-partners.net/pages/offers.html" className="navbarr-link">
                    Offers
                  </a>
                </li>
                <li>
                  <a href="https://ronald-partners.net/pages/contact-us.html" className="navbarr-link">
                    Contact
                  </a>
                </li>
              </ul>
              <div className="wrapper">
                <li>
                  <a href="tel:001234567890" className="contact-link">
                    Phone: +234(1)2953655
                  </a>
                </li>
              </div>
              <ul className="social-list">
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-facebook" />
                  </a>
                </li>
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-dribbble" />
                  </a>
                </li>
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-instagram" />
                  </a>
                </li>
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-youtube" />
                  </a>
                </li>
              </ul>
            </nav>
           
            <i className="nav-open-btn" onClick={handleClick}>
              <HiMenu />
            </i>
            <div className="overlay" data-nav-toggler data-overlay />
          </div>
        </header>
        <div className="form-contact">
          <div className="study-form-logo">
            <img
              // src={Logo}
              alt="Logo"
            />
          </div>
          <p className="address">
            Head Office: Ronald and Partners Consult, 1st Floor, Nurses House,
            PC 43, Church Gate Street, Victoria Island, Lagos State.
          </p>
          <p className="contact">
            Contact Mobile Numbers: +234(1)2953655, +2348034311748,
            +2349073666847
          </p>
        </div>
        <h3 className="form-heading">Login to apply and track your application.</h3>
      {loading && <Loader />}
      {success ? (
        <section>
          <h1>You are logged in!</h1>
          <br />
          <p>
            <a href="/dashboard">Go to Home</a>
          </p>
        </section>
      ) : (
        <div className="form-flex">

        <div className="container-form">
          <section>
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
              >
              {errMsg}
            </p>
            <div className="logo-auth">
              <h1>Sign In</h1>
              <img src={LogoBlack} alt="Ronald logo" className="logo" />
            </div>

            <form onSubmit={handleSubmit}>
              <label htmlFor="email">Email:</label>
              <input
                type="text"
                id="email"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
                />

              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
                />
              <button className="button">Sign In</button>
            </form>
            <p>
              Need an Account?
              <br />
              <span className="line">
                {/*put router link here*/}
                <a href="/signup">Sign Up</a>
              </span>
            </p>
          </section>
        </div>
      </div>
      )}
    </div>
    </div>
    
    );
};

export default LoginForm;
