import React, { useState } from 'react';
import { MdCalendarMonth, MdOutlineCancel } from 'react-icons/md';
import { State } from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from '../API/axios';

const UserModal = ({ open, ref, closeModal}) => {
  const [isLoading,setIsLoading]=useState(true)
  const navigate=useNavigate()
  //const [data,setData]=useState({})
  const {modalData,token}=State() 
  const data=modalData

  const acceptApplication=async(id)=>{

    try {
      const response=await axios.patch(
        `/form/${id}`,
        {status:"accepted"},
        {
          headers: { 'Content-Type': 'application/json' ,authorization:token},
          withCredentials: true
        }
  
      )
      navigate("/")
    } catch (error) {
      console.log(error)
    }

        // Using sweetalert
        const MySwal = withReactContent(Swal);

        MySwal.fire("Good job!", "Accepted successfully!");
    
  }
  const reworkApplication=async(id)=>{

    try {
      const response=await axios.patch(
        `/form/${id}`,
        {status:"rework"},
        {
          headers: { 'Content-Type': 'application/json' ,authorization:token},
          withCredentials: true
        }
        
      )
      navigate("/")
    } catch (error) {
      console.log(error)
    }
        // Using sweetalert
        const MySwal = withReactContent(Swal);

        MySwal.fire("Good job!", "You successfully told the user to rework application!");
    
  }

  return (
    <>
    <div id="myModal" class={open ? "podal pac" : "podal"}>
      <div class="podal-content" ref={ref}>
        <div class='split'>
          <div class='left'>
            <div className='msg-img' style={{backgroundImage: `url(${data.userId.profileImgUrl})`}}>
            </div>
            {
              data.status==="pending"?
                <div class='pod_but'>
                  <button onClick={()=>acceptApplication(data._id)}>Accept</button>
                  <button onClick={()=>reworkApplication(data._id)}>Rework</button>
                </div>
              :
              ""

            }
          </div>
          {
            data.formType==="Study Abroad"?
              <div class='right'>
                <p class='right_head'>Applicants Details</p>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>First Name</p>
                  <p class='right_para'>{data.firstname}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Middle Name</p>
                  <p class='right_para'>{data.middlename}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Last Name</p>
                  <p class='right_para'>{data.lastname}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Email Address</p>
                  <p class='right_para'>{data.email}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Gender</p>
                  <p class='right_para'>{data.gender}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Mobile Number</p>
                  <p class='right_para'>{data.mobilenumber}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Home Number</p>
                  <p class='right_para'>{data.homenumber}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Residential Address</p>
                  <p class='right_para'>{data.residential_address}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Country</p>
                  <p class='right_para'>{data.country}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>State</p>
                  <p class='right_para'>{data.state}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>City</p>
                  <p class='right_para'>{data.city}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Postal Code</p>
                  <p class='right_para'>{data.postal_code}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Student Comment</p>
                  <p class='right_para'>{data.student_comment}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Degree Type</p>
                  <p class='right_para'>{data.degree_type}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Degree Programme</p>
                  <p class='right_para'>{data.degree_programme}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Sponsor First Name</p>
                  <p class='right_para'>{data.sponsor_firstname}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Sponsor Last Name</p>
                  <p class='right_para'>{data.sponsor_lastname}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Sponsor Email Address</p>
                  <p class='right_para'>{data.sponsor_email}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Sponsor Mobile Number</p>
                  <p class='right_para'>{data.sponsor_mobileno}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Sponsor Address</p>
                  <p class='right_para'>{data.sponsor_address}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Sponsor Relationship Status</p>
                  <p class='right_para'>{data.sponsor_relationship}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Sponsor Date Of Birth</p>
                  <p class='right_para'>{data.sponsor_dateofbirth}</p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>International Passport</p>
                  {/* <p class='right_para'>{sponsor_address}</p> */}
                  <p><a href={data.international_passport}>view</a></p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Statement Of Purpose</p>
                  {/* <p class='right_para'>{sponsor_address}</p> */}
                  <p><a href={data.statementofpurpose}>view</a></p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Resume</p>
                  {/* <p class='right_para'>{sponsor_address}</p> */}
                  <p><a href={data.resume}>view</a></p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>O'Level Result</p>
                  {/* <p class='right_para'>{sponsor_address}</p> */}
                  <p><a href={data.waec_neco}>view</a></p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Degree Certificate</p>
                  {/* <p class='right_para'>{sponsor_address}</p> */}
                  <p><a href={data.degree_certificate}>view</a></p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Degree Transcript</p>
                  {/* <p class='right_para'>{sponsor_address}</p> */}
                  <p><a href={data.degree_transcript}>view</a></p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Employment Reference letter</p>
                  {/* <p class='right_para'>{sponsor_address}</p> */}
                  <p><a href={data.employment_reference_letter}>view</a></p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Academic Reference letter</p>
                  {/* <p class='right_para'>{sponsor_address}</p> */}
                  <p><a href={data.academic_reference_letter}>view</a></p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Change Name Document</p>
                  {/* <p class='right_para'>{sponsor_address}</p> */}
                  <p><a href={data.change_name_document}>view</a></p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Birth Certificate</p>
                  {/* <p class='right_para'>{sponsor_address}</p> */}
                  <p><a href={data.birth_certificate}>view</a></p>
                </div>
                <div class='right_content'>
                  <p><i><MdCalendarMonth /></i>Status</p>
                  <p class='right_para'>{data.status}</p>
                </div>
              </div>
            :
              data.formType==="Immigration"?
                <div class='right'>
                  <p class='right_head'>Applicants Details</p>
                  <div class='right_content'>
                    <p><i><MdCalendarMonth /></i>Name</p>
                    <p class='right_para'>{data.name}</p>
                  </div>
                  <div class='right_content'>
                    <p><i><MdCalendarMonth /></i>Email</p>
                    <p class='right_para'>{data.email}</p>
                  </div>
                  <div class='right_content'>
                    <p><i><MdCalendarMonth /></i>Mobile Number</p>
                    <p class='right_para'>{data.phone}</p>
                  </div>
                  <div class='right_content'>
                    <p><i><MdCalendarMonth /></i>Nationality</p>
                    <p class='right_para'>{data.nationality}</p>
                  </div>
                  <div class='right_content'>
                    <p><i><MdCalendarMonth /></i>Skills Specialization</p>
                    <p class='right_para'>{data.skillSpecialization}</p>
                  </div>
                  <div class='right_content'>
                    <p><i><MdCalendarMonth /></i>Education History</p>
                    <p class='right_para'>{data.educationHistory}</p>
                  </div>
                  <div class='right_content'>
                    <p><i><MdCalendarMonth /></i>Employment History</p>
                    <p class='right_para'>{data.employmentHistory}</p>
                  </div>
                  <div class='right_content'>
                    <p><i><MdCalendarMonth /></i>Purpose Of Travel</p>
                    <p class='right_para'>{data.purposeOfTravel}</p>
                  </div>
                  <div class='right_content'>
                    <p><i><MdCalendarMonth /></i>Note</p>
                    <p class='right_para'>{data.note}</p>
                  </div>
                  <div class='right_content'>
                    <p><i><MdCalendarMonth /></i>Status</p>
                    <p class='right_para'>{data.status}</p>
                  </div>
                </div>
              :
              // the thirm application form but for now it is null
                null
          }
          <i class='poti_cancel' onClick={closeModal}><MdOutlineCancel /></i>
        </div>
      </div>
    </div>
    </>
  )
};

export default UserModal;
