import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaUsers } from "react-icons/fa";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MdCalendarMonth, MdCancel, MdPending } from "react-icons/md";
import Sidebar from "../sidebar";
import UserModal from "../usermodal";
import "./dashboard.css";
import { State } from "../../context/AuthProvider";
import axios from "../../API/axios";
import NavAdmin from "../navAdmin";

const Dashboard = () => {
  const ref = useRef();
  const {
    user,
    token,
    applyFormData,
    setApplyFormData,
    pendingFormData,
    acceptedFormData,
    reworkFormData,
    setModalData,
  } = State();
  const ALLFORM_URL = "/form";
  //console.log(user)
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const handleToggle = () => setToggle(!toggle);
  const [change, setChange] = useState(false);
  const handleChange = () => setChange(!change);
  const [open, setOpen] = useState(false);
  const handlesetInterface = () => setOpen(!open);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);

  useEffect(() => {
    const getAllForm = async (req, res) => {
      const response = await axios.get(ALLFORM_URL, {
        headers: { "Content-Type": "application/json", authorization: token },
        withCredentials: true,
      });
      setApplyFormData(response.data);
      setModalData(response.data[0]);
    };
    getAllForm();

    setIsLoading(false);
  }, []);

  const handleModalOpen = (data) => {
    handlesetInterface();
    setModalData(data);
  };

  const acceptApplication = async (id) => {
    try {
      const response = await axios.patch(
        `/form/${id}`,
        { status: "accepted" },
        {
          headers: { "Content-Type": "application/json", authorization: token },
          withCredentials: true,
        }
      );
      navigate("/");
    } catch (error) {
      console.log(error);
    }
    // Using sweetalert
    const MySwal = withReactContent(Swal);

    MySwal.fire("Good job!", "Accepted successfully!");
  };

  const reworkApplication = async (id) => {
    try {
      const response = await axios.patch(
        `/form/${id}`,
        { status: "rework" },
        {
          headers: { "Content-Type": "application/json", authorization: token },
          withCredentials: true,
        }
      );
      navigate("/");
    } catch (error) {
      console.log(error);
    }

    // Using sweetalert
    const MySwal = withReactContent(Swal);

    MySwal.fire(
      "Good job!",
      "You successfully told the user to rework application!"
    );
  };

  // Get current month
  const currentMonth = new Date().getMonth() + 1;

  // Filter applications for current month
  const currentMonthApplications = applyFormData.filter((data) => {
    const date = new Date(data.createdAt);
    const month = date.getMonth() + 1;
    return month === currentMonth;
  });

  return (
    <>
      <Sidebar toggle={toggle} handleChange={() => handleChange()} />
      {/* End of header (component)*/}
      <main class="main-doc">
        <NavAdmin
          change={change}
          handleChange={() => handleChange()}
          handleClick={() => handleToggle()}
        />
        {/* End of navigation (component) */}

        <div class="main-container">
          <h3 class="i_name">Dashboard</h3>
          <div class="values">
            <div class="val_box">
              <i>
                <FaUsers />
              </i>
              <div>
                <h3>{applyFormData.length}</h3>
                <span>All Applicants</span>
              </div>
            </div>
            <div class="val_box">
              <i>
                <IoMdCheckmarkCircleOutline />
              </i>
              <div>
                <h3>{acceptedFormData.length}</h3>
                <span>Accepted Applicants</span>
              </div>
            </div>
            <div class="val_box">
              <i>
                <MdPending />
              </i>
              <div>
                <h3>{pendingFormData.length}</h3>
                <span>Pending Applicants</span>
              </div>
            </div>
            <div class="val_box">
              <i>
                <MdCancel />
              </i>
              <div>
                <h3>{reworkFormData.length}</h3>
                <span>Rework Applicants</span>
              </div>
            </div>
            <div class="val_box">
              <i>
                <MdCalendarMonth />
              </i>
              <div>
                <h3>{currentMonthApplications.length}</h3>
                <span>This Month</span>
              </div>
            </div>
          </div>

          <div class="pen_head">
            <h3>Pending</h3>
          </div>
          <div class="board">
            <table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Form Type</td>
                  <td>Date</td>
                  <td>Action</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {pendingFormData.slice(0, 5).map((data) => {
                  const date = new Date(data.createdAt);
                  const yyyy = date.getFullYear();
                  const mm = date.getMonth();
                  const dd = date.getDate();

                  return (
                    <tr key={data._id}>
                      <td class="people">
                        <img src={data.userId.profileImgUrl} alt="" />
                        <div class="people_des">
                          <h5 onClick={() => handleModalOpen(data)}>
                            {data.userId.name}
                          </h5>
                          <p onClick={() => handleModalOpen(data)}>
                            {data.userId.email}
                          </p>
                        </div>
                      </td>
                      <td class="people_design">
                        <h5>{data.formType}</h5>
                      </td>
                      <td class="tab_active">
                        <p>{dd + "/" + mm + "/" + yyyy}</p>
                      </td>
                      <td class="tab_role">
                        <p onClick={() => acceptApplication(data._id)}>
                          Accept
                        </p>
                      </td>
                      <td class="tab_edit">
                        <p
                          onClick={() => reworkApplication(data._id)}
                          style={{ color: "white" }}
                        >
                          Rework
                        </p>
                      </td>
                      <UserModal
                        open={open}
                        ref={ref}
                        // closeModal={handlesetInterface}
                      />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div class="pen_head">
            <h3>Rework</h3>
          </div>
          <div class="board">
            <table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Form Type</td>
                  <td>Created Date</td>
                  <td>Declined Date</td>
                </tr>
              </thead>
              <tbody>
                {reworkFormData.map((data) => {
                  //created date
                  const createdDate = new Date(data.createdAt);
                  const createdyyyy = createdDate.getFullYear();
                  const createdmm = createdDate.getMonth();
                  const createddd = createdDate.getDate();

                  // declined date
                  const declinedDate = new Date(data.updatedAt);
                  const declinedyyyy = declinedDate.getFullYear();
                  const declinedmm = declinedDate.getMonth();
                  const declineddd = declinedDate.getDate();
                  return (
                    <tr>
                      <td class="people">
                        <img
                          src={data.userId.profileImgUrl}
                          alt={data.userId.name}
                        />
                        <div class="people_des">
                          <h5 onClick={() => handleModalOpen(data)}>
                            {data.userId.name}
                          </h5>
                          <p onClick={() => handleModalOpen(data)}>
                            {data.userId.email}
                          </p>
                        </div>
                      </td>

                      <td className="people_design">
                        <h5>{data.formType}</h5>
                      </td>
                      <td className="tab_active">
                        <p>{createddd + "/" + createdmm + "/" + createdyyyy}</p>
                      </td>
                      <td className="tab_active">
                        <p>
                          {declineddd + "/" + declinedmm + "/" + declinedyyyy}
                        </p>
                      </td>
                      <UserModal open={open} ref={ref} />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
