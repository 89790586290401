// import jwtDecode from "jwt-decode";
// import { createContext, useContext, useEffect, useState } from "react";
// //import axios from "../API/axios";

// const AuthContext = createContext({});

// export const AuthProvider = ({ children }) => {
//     const [token, setToken] = useState(localStorage.getItem("token")?JSON.parse(localStorage.getItem("token")):null);
//     const [user,setUser]=useState(null)
//     const [applyFormData,setApplyFormData]=useState([])
//     const [pendingFormData,setPendingFormData]=useState(null)
//     const [acceptedFormData,setAcceptedFormData]=useState(null)
//     const [reworkFormData,setReworkFormData]=useState(null)
//     const [studyAbroadFormData,setStudyAbroadFormData]=useState(null)
//     const [immigrationFormData, setImmigrationFormData]=useState(null)
//     const [modalData,setModalData]=useState({})

//     const newToken=localStorage.getItem("token")

//     useEffect(()=>{
//         if(token===null) return setUser(null)

//         console.log("decoded")

//         const {user}=jwtDecode(token)
//         setUser(user)

//     },[token])


//     useEffect(()=>{
//         setToken(JSON.parse(localStorage.getItem("token")))
//         //const userInfo=JSON.parse(localStorage.getItem("userInfo"))
      
//     },[newToken])

//     useEffect(()=>{
//         const pending=applyFormData.filter((data)=>data.status === "pending")
//         setPendingFormData(pending)

//         const accepted=applyFormData.filter((data)=>data.status === "accepted")
//         setAcceptedFormData(accepted)

//         const rework=applyFormData.filter((data)=>data.status === "rework")
//         setReworkFormData(rework)

//         const immigrationData=applyFormData.filter((data)=>data.formType==="Immigration")
//         setImmigrationFormData(immigrationData)

//         const studyAbroadData=applyFormData.filter((data)=>data.formType==="Study Abroad")
//         setStudyAbroadFormData(studyAbroadData)

//     },[applyFormData])

   
    

//     console.log(applyFormData)

//     const state={ 
//         token, 
//         setToken,
//         user,
//         setUser,
//         applyFormData,
//         setApplyFormData,
//         pendingFormData,
//         acceptedFormData,
//         reworkFormData,
//         modalData,
//         setModalData,
//         studyAbroadFormData,
//         immigrationFormData
//     }

//     return (
//         <AuthContext.Provider value={state}>
//             {children}
//         </AuthContext.Provider>
//     )
// }

// export const State=()=>{
//     return useContext(AuthContext)
// }

// export default AuthContext;




import jwtDecode from "jwt-decode";
import { createContext, useContext, useEffect, useState } from "react";
import axios from "../API/axios";
import useAxios from "../components/hooks/useAxios";
//import axios from "../API/axios";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem("token")?JSON.parse(localStorage.getItem("token")):null);
    const [user,setUser]=useState(null)
    const [applyFormData,setApplyFormData]=useState([])
    const [pendingFormData,setPendingFormData]=useState(null)
    const [acceptedFormData,setAcceptedFormData]=useState(null)
    const [reworkFormData,setReworkFormData]=useState(null)
    const [studyAbroadFormData,setStudyAbroadFormData]=useState(null)
    const [immigrationFormData, setImmigrationFormData]=useState(null)
    const [modalData,setModalData]=useState({})
    const API=useAxios()

    const logOut=async()=>{
        await API.get("/logout",{withCredentials:true})
        setUser(null)
        setToken(null)
        localStorage.removeItem("token")
    }

    const newToken=localStorage.getItem("token")

    useEffect(()=>{
        if(token===null) return setUser(null)

        //console.log("decoded")

        const {user}=jwtDecode(token)
        setUser(user)

    },[token])

    console.log(user)


    useEffect(()=>{
        setToken(JSON.parse(localStorage.getItem("token")))
        //const userInfo=JSON.parse(localStorage.getItem("userInfo"))
      
    },[newToken])

    useEffect(()=>{
        const pending=applyFormData.filter((data)=>data.status === "pending")
        setPendingFormData(pending)

        const accepted=applyFormData.filter((data)=>data.status === "accepted")
        setAcceptedFormData(accepted)

        const rework=applyFormData.filter((data)=>data.status === "rework")
        setReworkFormData(rework)

        const immigrationData=applyFormData.filter((data)=>data.formType==="Immigration")
        setImmigrationFormData(immigrationData)

        const studyAbroadData=applyFormData.filter((data)=>data.formType==="Study Abroad")
        setStudyAbroadFormData(studyAbroadData)

    },[applyFormData])

   
    

    //console.log(applyFormData)

    const state={ 
        token, 
        setToken,
        user,
        setUser,
        applyFormData,
        setApplyFormData,
        pendingFormData,
        acceptedFormData,
        reworkFormData,
        modalData,
        setModalData,
        studyAbroadFormData,
        immigrationFormData,
        logOut
    }

    return (
        <AuthContext.Provider value={state}>
            {children}
        </AuthContext.Provider>
    )
}

export const State=()=>{
    return useContext(AuthContext)
}

export default AuthContext;