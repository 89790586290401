import React from "react";
import { BsSearch, BsBell } from "react-icons/bs";
import { HiMenu } from "react-icons/hi";
import { MdOutlineCancel } from "react-icons/md";
import { State } from "../context/AuthProvider";

const NavAdmin = ({ change, handleClick, handleChange }) => {
  const { user } = State();
  return (
    <>
      <nav>
        <div className="sidebar-button">
          <i onClick={handleClick}>
            {" "}
            <HiMenu />
          </i>
        </div>
        {/* <div className="search-box">
          <input type="text" placeholder="Search..."></input>
          <i className="bx-search">
            {" "}
            <BsSearch />{" "}
          </i>
        </div> */}
        <div className="profile-details">
          {/* <i className='bell' onClick={handleChange}> <BsBell /> </i> */}
          <span className="nav-link-back">
            <a href="https://ronald-partners.net">Home</a>
          </span>
          <span className="nav-link-back">
            <a href="https://ronald-partners.net/pages/contact-us.html">About</a>
          </span>
        </div>
        <div id="myModal" class={change ? "modal action" : "modal"}>
          <div class="modal-content">
            <p class="noti_head">Notifications</p>
            <p class="noti_para">Some text in the Modal..</p>
            <p class="noti_para">Some text in the Modal..</p>
            <p class="noti_para">Some text in the Modal..</p>
            <p class="noti_para">Some text in the Modal..</p>
            <p class="noti_para">Some text in the Modal..</p>
            <i class="noti_cancel" onClick={handleChange}>
              <MdOutlineCancel />
            </i>
          </div>
        </div>
      </nav>
      {/* End of navigation (component) */}
    </>
  );
};

export default NavAdmin;

