/* eslint-disable import/no-cycle */
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./components/pages/dashboard";
import Profile from "./components/pages/profile";
import Pending from "./components/pages/pending";
import Reject from "./components/pages/reject";
import Applicants from "./components/pages/applicants";
import Setting from "./components/pages/setting";
import StudentDashboard from "./components/pages/studentdashboard";
//import PrivateRoutes from "./components/PrivateRoutes";
// import Login from "./components/auths/Login";
import LoginForm from "./components/auths/LoginForm";
import Register from "./components/auths/Register";
import ProtectedRoute, { UnProtectedRoute } from "./components/auths/ProtectedRoute";
import AdminProtectedRoute from "./components/auths/AdminProtection";
import Apply from "./components/pages/apply";
import Accept from "./components/pages/accepted";

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route element={<AdminProtectedRoute />}> 
          <Route path="/" element={<Dashboard />}></Route>
          <Route path="/pending" element={<Pending />}></Route>
          <Route path="/reject" element={<Reject />}></Route>
          <Route path="/applicants" element={<Applicants />}></Route>
          <Route path="/accept" element={<Accept />}/>
        </Route> 
        <Route path="/setting" element={<Setting />}></Route>
        <Route path="/profile" element={<Profile />}></Route>
        <Route path="/apply" element={<Apply />} />
        <Route path="/student" element={<StudentDashboard />}></Route>
      </Route>

      <Route element={<UnProtectedRoute />} > 
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/login" element={<LoginForm />} />
        <Route path="/signup" element={<Register />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default App;
