import React, { useState,useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../sidebar';
import './dashboard.css';
import { State } from '../../context/AuthProvider';
import UserModal from '../usermodal';
import NavAdmin from '../navAdmin';

const Reject = () => {
  const ref=useRef()
  const {reworkFormData,setModalData}=State()
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => setToggle(!toggle);
  const [change, setChange] = useState(false);
  const handleChange = () => setChange(!change);
  const [open, setOpen] = useState(false);
  const handlesetInterface = () => setOpen(!open);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [ref]);

  const handleModalOpen=(data)=>{
    handlesetInterface()
    setModalData(data)
  }

  return (
    <>
      <Sidebar 
        toggle={toggle}
        handleChange={() => handleChange()}
      />
      { /* End of header (component)*/ }
      <main class="main-doc">
      
       <NavAdmin
         change={change}
         handleChange={() => handleChange()}
         handleClick={() => handleToggle()}
      />
        { /* End of navigation (component) */}

        <div class="main-container">
          <div class='pen_head'>
            <h3>Rework Application</h3>
          </div>
          <div class='board'>
            <table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Form Type</td>
                  <td>Created Date</td>
                  <td>Declined Date</td>
                </tr>
              </thead>
              <tbody>
                {
                  reworkFormData.map((data)=>{
                    //created date
                    const createdDate=new Date(data.createdAt)
                    const createdyyyy=createdDate.getFullYear()
                    const createdmm=createdDate.getMonth()
                    const createddd=createdDate.getDate()

                    // declined date
                    const declinedDate= new Date(data.updatedAt)
                    const declinedyyyy=declinedDate.getFullYear()
                    const declinedmm=declinedDate.getMonth()
                    const declineddd=declinedDate.getDate()
                    return(
                      <tr>
                        <td class='people'>
                          <img src={data.userId.profileImgUrl} alt={data.userId.name} />
                          <div class='people_des'>
                            <h5 onClick={()=> handleModalOpen(data)}>{data.userId.name}</h5>
                            <p onClick={()=> handleModalOpen(data)}>{data.userId.email}</p>
                          </div>
                        </td>

                        <td className='people_design'>
                          <h5>{data.formType}</h5>
                        </td>
                        <td className='tab_active'>
                          <p>{createddd+"/"+createdmm+"/"+createdyyyy}</p>
                        </td>
                        <td className='tab_active'>
                          <p>{declineddd+"/"+declinedmm+"/"+declinedyyyy}</p>
                        </td>
                        <UserModal 
                          open={open}
                          ref={ref}
                          data={data}
                        />
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </>
  )
};

export default Reject;
