import React, { useState } from "react";
import Sidebar from "../sidebar";
import Nav from "../nav";
import axios from "../../API/axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./profile.css";
import { State } from "../../context/AuthProvider";
import { uploadImage } from "../../API/imageUploadRequest";
import StudentNav from "../studentnav";
const CREATE_PROFILE_URL = "/profile";

const Profile = () => {

  

  const { token, user, setToken } = State();
  const UPDATE_PROFILE_URL = `/profile/${user._id}`;
  const [profileForm, setProfileForm] = useState({
    placeOfBirth: "",
    countryOfOrigin: "",
    proposeCourseOfStudy: "",
    proposeCountryOfStudy: "",
    reasonToStudy: "",
    purposeOfTravel: "",
  });
  const [userForm, setUserForm] = useState({
    profileImgUrl: "",
  });
  const [documentForm, setDocumentForm] = useState({
    passport: "",
    identityCard: "",
  });
  const [image, setImage] = useState(null);
  const [identityCard, setIdentityCard] = useState(null);
  const [passport, setPassport] = useState(null);
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => setToggle(!toggle);
  const [change, setChange] = useState(false);
  const handleChange = () => setChange(!change);

  const cloud_name = process.env.REACT_APP_CLOUD_NAME;
  const upload_preset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;


    


  const updateProfilePic = async (e) => {
    //console.log(image)
    const imageData = new FormData();
    imageData.append("file", image);
    imageData.append("upload_preset", upload_preset);
    imageData.append("cloud_name", cloud_name);
    try {
      const imgResponse = await uploadImage(imageData);

      userForm.profileImgUrl = imgResponse.data.url.toString();
      //console.log(imgResponse.data.url.toString())

      const profileImgUrl = userForm.profileImgUrl;

      const response = await axios.patch(
        UPDATE_PROFILE_URL,
        { profileImgUrl },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `${token}`,
          },
          withCredentials: true,
        }
      );
      const accessToken = response?.data?.user_token;
      // const roles = response?.data?.roles;
      setToken(accessToken);
      localStorage.setItem("token", JSON.stringify(accessToken));
      //console.log(response)
    } catch (error) {
      console.log(error);
    }

      // Using sweetalert
      const MySwal = withReactContent(Swal);

      MySwal.fire("Good job!", "You updated your profile picture successfully!");
  };

  

  const updateDocument = async (e) => {
    //console.log(image)
    try {
      if (identityCard) {
        const imageData = new FormData();
        imageData.append("file", identityCard);
        imageData.append("upload_preset", upload_preset);
        imageData.append("cloud_name", cloud_name);

        const imgResponse = await uploadImage(imageData);
        documentForm.identityCard = imgResponse.data.url.toString();
        //console.log(imgResponse.data.url.toString())
      }

      if (passport) {
        const imageData = new FormData();
        imageData.append("file", passport);
        imageData.append("upload_preset", upload_preset);
        imageData.append("cloud_name", cloud_name);

        const imgResponse = await uploadImage(imageData);
        documentForm.passport = imgResponse.data.url.toString();
        //console.log(imgResponse.data.url.toString())
      }

      //const profileImgUrl=userForm.profileImgUrl

      const response = await axios.patch(UPDATE_PROFILE_URL, documentForm, {
        headers: {
          "Content-Type": "application/json",
          authorization: `${token}`,
        },
        withCredentials: true,
      });
      const accessToken = response?.data?.user_token;
      // const roles = response?.data?.roles;
      setToken(accessToken);
      localStorage.setItem("token", JSON.stringify(accessToken));
      //console.log(response)
    } catch (error) {
      console.log(error);
    }
      // Using sweetalert
      const MySwal = withReactContent(Swal);

      MySwal.fire("Good job!", "You updated your document successfully!");
  };


  


  const updateProfile = async (e) => {
    //console.log(profileForm)

    try {
      const response = await axios.post(CREATE_PROFILE_URL, profileForm, {
        headers: {
          "Content-Type": "application/json",
          authorization: `${token}`,
        },
        withCredentials: true,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }

    // Using sweetalert
    const MySwal = withReactContent(Swal);

    MySwal.fire("Good job!", "You updated your profile successfully!");
  };

  const handleProfileChange = (e) => {
    setProfileForm({ ...profileForm, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Sidebar toggle={toggle} handleChange={() => handleChange()} />
      {/* End of header (component)*/}
      <main class="main-doc">
       
         <StudentNav 
           change={change}
           handleChange={() => handleChange()}
           handleClick={() => handleToggle()}
        />
        {/* End of navigation (component) */}

        <div class="main-profile">
          <h3 class="i_name">Profile</h3>
          <div class="profile_cont">
            <div class="profile_head">
              <h4>Personal Information</h4>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Name</p>
                <input type="text" value={user.name} />
              </div>
              <div class="input_box">
                <p>Email</p>
                <input type="text" value={user.email} />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Place Of Birth</p>
                <input
                  name="placeOfBirth"
                  type="text"
                  onChange={handleProfileChange}
                />
              </div>
              <div class="input_box">
                <p>Country Of Origin</p>
                <input
                  name="countryOfOrigin"
                  type="text"
                  onChange={handleProfileChange}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Course</p>
                <input
                  name="proposeCourseOfStudy"
                  type="text"
                  onChange={handleProfileChange}
                />
              </div>
              <div class="input_box">
                <p>Country To Study</p>
                <input
                  name="proposeCountryOfStudy"
                  type="text"
                  onChange={handleProfileChange}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_boxes">
                <p>Reason To Study</p>
                <textarea
                  name="reasonToStudy"
                  onChange={handleProfileChange}
                ></textarea>
              </div>
              <div class="input_boxes">
                <p>Purpose Of Travel</p>
                <textarea
                  name="purposeOfTravel"
                  onChange={handleProfileChange}
                ></textarea>
              </div>
            </div>
            <div class="per_button">
              <button onClick={updateProfile}>Update</button>
            </div>
          </div>
          <div class="profile_cont">
            <div class="profile_head">
              <h4>Picture</h4>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Update profile</p>
                <input
                  type="file"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div>
            </div>
            <div class="per_button">
              <button onClick={updateProfilePic}>Update</button>
            </div>
          </div>
          <div class="profile_cont">
            <div class="profile_head">
              <h4>Documents</h4>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Passport</p>
                <input
                  type="file"
                  onChange={(e) => setPassport(e.target.files[0])}
                />
              </div>
              <div class="input_box">
                <p>Identity Card</p>
                <input
                  type="file"
                  onChange={(e) => setIdentityCard(e.target.files[0])}
                />
              </div>
            </div>
            <div class="per_button">
              <button onClick={updateDocument}>Update</button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Profile;
