import React, { useState, useEffect } from "react";
import { FaUsers } from "react-icons/fa";
import { PiStudentBold } from "react-icons/pi";
import { RiVisaLine } from "react-icons/ri";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdCalendarMonth, MdPending } from "react-icons/md";
import Sidebar from "../sidebar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import Nav from "../nav";
import "./studentdashboard.css";
import { State } from "../../context/AuthProvider";
import axios from "../../API/axios";
import StudentNav from "../studentnav";
const EACH_STUDENT_FORM_URL = "/form";

const StudentDashboard = () => {
  const [toggle, setToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const handleToggle = () => setToggle(!toggle);
  const [change, setChange] = useState(false);
  const handleChange = () => setChange(!change);
  const {
    user,
    token,
    setApplyFormData,
    applyFormData,
    setModalData,
    pendingFormData,
    acceptedFormData,
    reworkFormData,
    studyAbroadFormData,
    immigrationFormData,
  } = State();

  useEffect(() => {
    const getAllForm = async (req, res) => {
      const response = await axios.get(EACH_STUDENT_FORM_URL + "/" + user._id, {
        headers: { "Content-Type": "application/json", authorization: token },
        withCredentials: true,
      });
      setApplyFormData(response.data);
      setModalData(response.data[0]);
    };
    getAllForm();

    setIsLoading(false);
  }, []);

  //console.log(applyFormData)
  const deleteApplication = async (id) => {
    try {
      const response = await axios.delete(`/form/${id}`, {
        headers: { "Content-Type": "application/json", authorization: token },
        withCredentials: true,
      });
      //console.log(response)
    } catch (error) {
      console.log(error);
    }
    const MySwal = withReactContent(Swal);

    MySwal.fire("Good job!", "Application successfully deleted!");
  };

  // Get current month
  const currentMonth = new Date().getMonth() + 1;

  // Filter applications for current month
  const currentMonthApplications = applyFormData.filter((data) => {
    const date = new Date(data.createdAt);
    const month = date.getMonth() + 1;
    return month === currentMonth;
  });

  return (
    <>
      <Sidebar toggle={toggle} handleChange={() => handleChange()} />
      {/* End of header (component)*/}
      <main class="main-doc">
        {/* <Nav
          change={change}
          handleChange={() => handleChange()}
          handleClick={() => handleToggle()}
        /> */}
        <StudentNav 
           change={change}
           handleChange={() => handleChange()}
           handleClick={() => handleToggle()}
        />
        {/* End of navigation (component) */}

        <div class="main-container">
          <h3 class="i_name">Welcome Home, {user?.name}</h3>
          <div class="values">
            <div class="val_box">
              <i>
                <FaUsers />
              </i>
              <div>
                <h3>{applyFormData.length}</h3>
                <span>Your Applications</span>
              </div>
            </div>
            {/* <div class='val_box'>
              <i><RiVisaLine/></i>
              <div>
                <h3>4</h3>
                <span>Visa Applications</span>
              </div>
            </div> */}
            <div class="val_box">
              <i>
                <RiVisaLine />
              </i>
              <div>
                <h3>{immigrationFormData.length}</h3>
                <span>Imigration Applications</span>
              </div>
            </div>
            <div class="val_box">
              <i>
                <PiStudentBold />
              </i>
              <div>
                <h3>{studyAbroadFormData.length}</h3>
                <span>Study Applications</span>
              </div>
            </div>
            <div class="val_box">
              <i>
                <IoMdCheckmarkCircleOutline />
              </i>
              <div>
                <h3>{acceptedFormData.length}</h3>
                <span>Accepted Applications</span>
              </div>
            </div>
            <div class="val_box">
              <i>
                <MdCalendarMonth />
              </i>
              <div>
                <h3>{currentMonthApplications.length}</h3>
                <span>This Month</span>
              </div>
            </div>
          </div>
          {/* <div class='pen_head'>
            <h3>Visa Application</h3>
          </div>
          <div class='board'>
            <table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Title</td>
                  <td>Type</td>
                  <td>Status</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class='people'>
                    <img src='https://demos.creative-tim.com/argon-dashboard/assets/img/team-3.jpg' alt='' />
                    <div class='people_des'>
                      <h5>John</h5>
                      <p>john@example.com</p>
                    </div>
                  </td>
                  <td class='people_design'>
                    <h5>Software Engineer</h5>
                    <p>Web dev</p>
                  </td>
                  <td class='tab_active'>
                    <p>Visa</p>
                  </td>
                  <td class='tab_role'>
                    <p>Awaiting</p>
                  </td>
                  <td class='tab_delete'>
                    <p style={{color: 'red'}}>Delete</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class='pen_head'>
            <h3>Study Application</h3>
          </div>
          <div class='board'>
            <table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Title</td>
                  <td>Type</td>
                  <td>Status</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class='people'>
                    <img src='https://demos.creative-tim.com/argon-dashboard/assets/img/team-3.jpg' alt='' />
                    <div class='people_des'>
                      <h5>John</h5>
                      <p>john@example.com</p>
                    </div>
                  </td>
                  <td class='people_design'>
                    <h5>Software Engineer</h5>
                    <p>Web dev</p>
                  </td>
                  <td class='tab_active'>
                    <p>Visa</p>
                  </td>
                  <td class='tab_role'>
                    <p>Awaiting</p>
                  </td>
                  <td class='tab_delete'>
                    <p style={{color: 'red'}}>Delete</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class='pen_head'>
            <h3>Immigration Application</h3>
          </div>
          <div class='board'>
            <table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Title</td>
                  <td>Type</td>
                  <td>Status</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class='people'>
                    <img src='https://demos.creative-tim.com/argon-dashboard/assets/img/team-3.jpg' alt='' />
                    <div class='people_des'>
                      <h5>John</h5>
                      <p>john@example.com</p>
                    </div>
                  </td>
                  <td class='people_design'>
                    <h5>Software Engineer</h5>
                    <p>Web dev</p>
                  </td>
                  <td class='tab_active'>
                    <p>Visa</p>
                  </td>
                  <td class='tab_role'>
                    <p>Awaiting</p>
                  </td>
                  <td class='tab_delete'>
                    <p style={{color: 'red'}}>Delete</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}
          <div class="pen_head">
            <h3>Pending Application</h3>
          </div>
          <div class="board">
            <table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Form Type</td>
                  <td>Date</td>
                  <td>Status</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {pendingFormData.map((data) => {
                  const createdDate = new Date(data.createdAt);
                  const createdyyyy = createdDate.getFullYear();
                  const createdmm = createdDate.getMonth();
                  const createddd = createdDate.getDate();
                  return (
                    <tr>
                      <td class="people">
                        <img
                          src={data.userId.profileImgUrl}
                          alt={data.userId.name}
                        />
                        <div class="people_des">
                          <h5>{data.userId.name}</h5>
                          <p>{data.userId.email}</p>
                        </div>
                      </td>
                      <td class="people_design">
                        <h5>{data.formType}</h5>
                      </td>
                      <td class="tab_active">
                        <p>{createddd + "/" + createdmm + "/" + createdyyyy}</p>
                      </td>
                      <td class="tab_role">
                        <p style={{ textTransform: "capitalize" }}>
                          {data.status}
                        </p>
                      </td>
                      <td class="tab_delete">
                        <p
                          onClick={() => deleteApplication(data._id)}
                          style={{ color: "red" }}
                        >
                          Delete
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div class="pen_head">
            <h3>Accepted Application</h3>
          </div>
          <div class="board">
            <table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Form Type</td>
                  <td>Date</td>
                  <td>Status</td>
                  {/* <td>Action</td> */}
                </tr>
              </thead>
              <tbody>
                {acceptedFormData.map((data) => {
                  const createdDate = new Date(data.createdAt);
                  const createdyyyy = createdDate.getFullYear();
                  const createdmm = createdDate.getMonth();
                  const createddd = createdDate.getDate();
                  return (
                    <tr>
                      <td class="people">
                        <img
                          src={data.userId.profileImgUrl}
                          alt={data.userId.name}
                        />
                        <div class="people_des">
                          <h5>{data.userId.name}</h5>
                          <p>{data.userId.email}</p>
                        </div>
                      </td>
                      <td class="people_design">
                        <h5>{data.formType}</h5>
                      </td>
                      <td class="tab_active">
                        <p>{createddd + "/" + createdmm + "/" + createdyyyy}</p>
                      </td>
                      <td class="tab_role">
                        <p style={{ textTransform: "capitalize" }}>
                          {data.status}
                        </p>
                      </td>
                      {/* <td class='tab_delete'>
                          <p onClick={()=>deleteApplication(data._id)} style={{color: 'red'}}>Delete</p>
                        </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div class="pen_head">
            <h3>Rework Application</h3>
          </div>
          <div class="board">
            <table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Form Type</td>
                  <td>Date</td>
                  <td>Status</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {reworkFormData.map((data) => {
                  const createdDate = new Date(data.createdAt);
                  const createdyyyy = createdDate.getFullYear();
                  const createdmm = createdDate.getMonth();
                  const createddd = createdDate.getDate();
                  return (
                    <tr>
                      <td class="people">
                        <img
                          src={data.userId.profileImgUrl}
                          alt={data.userId.name}
                        />
                        <div class="people_des">
                          <h5>{data.userId.name}</h5>
                          <p>{data.userId.email}</p>
                        </div>
                      </td>
                      <td class="people_design">
                        <h5>{data.formType}</h5>
                      </td>
                      <td class="tab_active">
                        <p>{createddd + "/" + createdmm + "/" + createdyyyy}</p>
                      </td>
                      <td class="tab_role">
                        <p style={{ textTransform: "capitalize" }}>
                          {data.status}
                        </p>
                      </td>
                      <td class="tab_delete">
                        <p
                          onClick={() => deleteApplication(data._id)}
                          style={{ color: "red" }}
                        >
                          Delete
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </>
  );
};

export default StudentDashboard;
