import React, { useState } from "react";
import Sidebar from "../sidebar";
// import Nav from "../nav";
import axios from "../../API/axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./profile.css";
import { State } from "../../context/AuthProvider";
import { uploadImage } from "../../API/imageUploadRequest";
import loadingAnimation from "../../assets/images/Rolling.svg";
import { useNavigate } from "react-router-dom";
import StudentNav from "../studentnav";
const CREATE_APPLICATION_URL = "/application";
const CREATE_IMMIGRATION_URL = "/immigration";

const Apply = () => {
  const [applicationFormData, setApplicationFormData] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    email: "",
    confirm_email: "",
    mobilenumber: "",
    homenumber: "",
    country: "",
    state: "",
    city: "",
    gender: "male",
    postal_code: "",
    residential_address: "",
    student_comment: "",
    degree_type: "",
    degree_programme: "",
    // sponsor details
    sponsor_firstname: "",
    sponsor_lastname: "",
    sponsor_email: "",
    confirm_sponsor_email: "",
    sponsor_mobileno: "",
    sponsor_address: "",
    sponsor_relationship: "single",
    sponsor_dateofbirth: "",
    international_passport: "",
    statementofpurpose: "",
    resume: "",
    waec_neco: "",
    degree_certificate: "",
    degree_transcript: "",
    employment_reference_letter: "",
    academic_reference_letter: "",
    change_name_document: "",
    birth_certificate: "",
  });

  const [immigrationFormData, setImmigrationFormData] = useState({
    name: "",
    nationality: "",
    email: "",
    phone: "",
    skillSpecialization: "",
    educationHistory: "",
    purposeOfTravel: "",
    employmentHistory: "",
    note: "",
  });
  const { token } = State();
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => setToggle(!toggle);
  const [change, setChange] = useState(false);
  const handleChange = () => setChange(!change);

  const [international_passport_file, setInternational_passport_file] =
    useState();
  const [isLoading, setIsLoading] = useState(false);
  const [statementofpurposeFile, setStatementofpurposeFile] = useState();
  const [resumeFile, setResumeFile] = useState();
  const [waec_neco_file, setWaec_neco_file] = useState();
  const [degreeCertificateFile, setDegreeCertificateFile] = useState();
  const [degreeTranscriptFile, setDegreeTranscriptFile] = useState();
  const [academicReferenceLetterFile, setAcademicReferenceLetterFile] =
    useState();
  const [employmentReferenceLetterFile, setEmploymentReferenceLetterFile] =
    useState();
  const [changeNameDocumentFile, setChangeNameDocumentFile] = useState();
  const [birthCertificateFile, setBirthCertificateFile] = useState();

  const handleApplicationChange = (e) => {
    setApplicationFormData({
      ...applicationFormData,
      [e.target.name]: e.target.value,
    });
  };
  const handleImmigrationChange = (e) => {
    setImmigrationFormData({
      ...immigrationFormData,
      [e.target.name]: e.target.value,
    });
  };

  const cloud_name = process.env.REACT_APP_CLOUD_NAME;
  const upload_preset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;

  const createApplication = async (e) => {
    // console.log(applicationFormData)
    //console.log(fileData)
    // console.log(international_passport_file)
    setIsLoading(true);
    if (international_passport_file) {
      const imageData = new FormData();
      imageData.append("file", international_passport_file);
      imageData.append("upload_preset", upload_preset);
      imageData.append("cloud_name", cloud_name);

      try {
        const imgResponse = await uploadImage(imageData);
        applicationFormData.international_passport =
          imgResponse.data.url.toString();
      } catch (error) {
        console.log(error);
      }
    }
    if (statementofpurposeFile) {
      const imageData = new FormData();
      imageData.append("file", statementofpurposeFile);
      imageData.append("upload_preset", upload_preset);
      imageData.append("cloud_name", cloud_name);

      try {
        const imgResponse = await uploadImage(imageData);
        applicationFormData.statementofpurpose =
          imgResponse.data.url.toString();
      } catch (error) {
        console.log(error);
      }
    }
    if (resumeFile) {
      const imageData = new FormData();
      imageData.append("file", resumeFile);
      imageData.append("upload_preset", upload_preset);
      imageData.append("cloud_name", cloud_name);

      try {
        const imgResponse = await uploadImage(imageData);
        applicationFormData.resume = imgResponse.data.url.toString();
      } catch (error) {
        console.log(error);
      }
    }
    if (waec_neco_file) {
      const imageData = new FormData();
      imageData.append("file", waec_neco_file);
      imageData.append("upload_preset", upload_preset);
      imageData.append("cloud_name", cloud_name);

      try {
        const imgResponse = await uploadImage(imageData);
        applicationFormData.waec_neco = imgResponse.data.url.toString();
      } catch (error) {
        console.log(error);
      }
    }
    if (degreeCertificateFile) {
      const imageData = new FormData();
      imageData.append("file", degreeCertificateFile);
      imageData.append("upload_preset", upload_preset);
      imageData.append("cloud_name", cloud_name);

      try {
        const imgResponse = await uploadImage(imageData);
        applicationFormData.degree_certificate =
          imgResponse.data.url.toString();
      } catch (error) {
        console.log(error);
      }
    }
    if (degreeTranscriptFile) {
      const imageData = new FormData();
      imageData.append("file", degreeTranscriptFile);
      imageData.append("upload_preset", upload_preset);
      imageData.append("cloud_name", cloud_name);

      try {
        const imgResponse = await uploadImage(imageData);
        applicationFormData.degree_transcript = imgResponse.data.url.toString();
      } catch (error) {
        console.log(error);
      }
    }
    if (employmentReferenceLetterFile) {
      const imageData = new FormData();
      imageData.append("file", employmentReferenceLetterFile);
      imageData.append("upload_preset", upload_preset);
      imageData.append("cloud_name", cloud_name);

      try {
        const imgResponse = await uploadImage(imageData);
        applicationFormData.employment_reference_letter =
          imgResponse.data.url.toString();
      } catch (error) {
        console.log(error);
      }
    }
    if (academicReferenceLetterFile) {
      const imageData = new FormData();
      imageData.append("file", academicReferenceLetterFile);
      imageData.append("upload_preset", upload_preset);
      imageData.append("cloud_name", cloud_name);

      try {
        const imgResponse = await uploadImage(imageData);
        applicationFormData.academic_reference_letter =
          imgResponse.data.url.toString();
      } catch (error) {
        console.log(error);
      }
    }
    if (changeNameDocumentFile) {
      const imageData = new FormData();
      imageData.append("file", changeNameDocumentFile);
      imageData.append("upload_preset", upload_preset);
      imageData.append("cloud_name", cloud_name);

      try {
        const imgResponse = await uploadImage(imageData);
        applicationFormData.change_name_document =
          imgResponse.data.url.toString();
      } catch (error) {
        console.log(error);
      }
    }
    if (birthCertificateFile) {
      const imageData = new FormData();
      imageData.append("file", birthCertificateFile);
      imageData.append("upload_preset", upload_preset);
      imageData.append("cloud_name", cloud_name);

      try {
        const imgResponse = await uploadImage(imageData);
        applicationFormData.birth_certificate = imgResponse.data.url.toString();
      } catch (error) {
        console.log(error);
      }
    }

    try {
      const response = await axios.post(
        CREATE_APPLICATION_URL,
        applicationFormData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `${token}`,
          },
          withCredentials: true,
        }
      );
      setIsLoading(false);
      navigate("/student");
    } catch (error) {
      console.log(error);
    }

    //  Using sweet alert
    const MySwal = withReactContent(Swal);

    MySwal.fire("Good job!", "You Applied successfully!");

    //   MySwal.fire({
    //     title: <p>Hello World</p>,
    //     didOpen: () => {
    //       // `MySwal` is a subclass of `Swal` with all the same instance & static methods
    //       MySwal.showLoading();
    //     },
    //   }).then(() => {
    //     // return MySwal.fire(<p>Shorthand works too</p>);
    //     return MySwal.fire(<p>Shorthand works too</p>);
    //   });
  };
  const createImmigration = async (e) => {
    console.log(immigrationFormData);

    try {
      const response = await axios.post(
        CREATE_IMMIGRATION_URL,
        immigrationFormData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `${token}`,
          },
          withCredentials: true,
        }
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }

    // Using sweetalert
    const MySwal = withReactContent(Swal);

    MySwal.fire("Good job!", "You Applied successfully!");
  };

  return (
    <>
      <Sidebar toggle={toggle} handleChange={() => handleChange()} />
      {/* End of header (component)*/}
      <main class="main-doc">
       
         <StudentNav 
           change={change}
           handleChange={() => handleChange()}
           handleClick={() => handleToggle()}
        />
        {/* End of navigation (component) */}

        <div class="main-profile">
          <h3 class="i_name">Apply</h3>
          <div class="profile_cont">
            <div class="profile_head">
              <h4>STUDY ABROAD APPLICATION FORM</h4>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>First Name</p>
                <input
                  name="firstname"
                  type="text"
                  onChange={handleApplicationChange}
                />
              </div>
              <div class="input_box">
                <p>Middle Name</p>
                <input
                  name="middlename"
                  type="text"
                  onChange={handleApplicationChange}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Last Name</p>
                <input
                  name="lastname"
                  type="text"
                  onChange={handleApplicationChange}
                />
              </div>
              <div class="input_box">
                <p>Email</p>
                <input
                  name="email"
                  type="email"
                  onChange={handleApplicationChange}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Confirm Email</p>
                <input
                  name="confirm_email"
                  type="email"
                  onChange={handleApplicationChange}
                />
              </div>
              <div class="input_box">
                <p>Mobile Number</p>
                <input
                  name="mobilenumber"
                  type="text"
                  onChange={handleApplicationChange}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Home Number</p>
                <input
                  name="homenumber"
                  type="text"
                  onChange={handleApplicationChange}
                />
              </div>
              <div class="input_box">
                <p>Country</p>
                <input
                  name="country"
                  type="text"
                  onChange={handleApplicationChange}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>State</p>
                <input
                  name="state"
                  type="text"
                  onChange={handleApplicationChange}
                />
              </div>
              <div class="input_box">
                <p>City</p>
                <input
                  name="city"
                  type="text"
                  onChange={handleApplicationChange}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Gender</p>
                <select
                  name="gender"
                  onChange={(e) =>
                    (applicationFormData.gender = e.target.value)
                  }
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div class="input_box">
                <p>Postal Code</p>
                <input
                  name="postal_code"
                  type="text"
                  onChange={handleApplicationChange}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_boxes">
                <p>Residential Address</p>
                <textarea
                  name="residential_address"
                  onChange={handleApplicationChange}
                ></textarea>
              </div>
              <div class="input_boxes">
                <p>Student Comment</p>
                <textarea
                  name="student_comment"
                  onChange={handleApplicationChange}
                ></textarea>
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Degree Type</p>
                <input
                  name="degree_type"
                  type="text"
                  onChange={handleApplicationChange}
                />
              </div>
              <div class="input_box">
                <p>Degree Programme</p>
                <input
                  name="degree_programme"
                  type="text"
                  onChange={handleApplicationChange}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Sponsor First Name:</p>
                <input
                  name="sponsor_firstname"
                  type="text"
                  onChange={handleApplicationChange}
                />
              </div>
              <div class="input_box">
                <p>Sponsor Last Name</p>
                <input
                  name="sponsor_lastname"
                  type="text"
                  onChange={handleApplicationChange}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Sponsor Email Address</p>
                <input
                  name="sponsor_email"
                  type="email"
                  onChange={handleApplicationChange}
                />
              </div>
              <div class="input_box">
                <p>Confirm Sponsor Email Address</p>
                <input
                  name="confirm_sponsor_email"
                  type="email"
                  onChange={handleApplicationChange}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Sponsor Mobile Number</p>
                <input
                  name="sponsor_mobileno"
                  type="text"
                  onChange={handleApplicationChange}
                />
              </div>
              <div class="input_box">
                <p>Sponsor Address</p>
                <input
                  name="sponsor_address"
                  type="text"
                  onChange={handleApplicationChange}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Sponsor Relationship status</p>
                <select
                  name="sponsor_relationship"
                  onChange={(e) =>
                    (applicationFormData.sponsor_relationship = e.target.value)
                  }
                >
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="divorced">Divorced</option>
                </select>
              </div>
              <div class="input_box">
                <p>Sponsor Date Of Birth</p>
                <input
                  name="sponsor_dateofbirth"
                  type="text"
                  onChange={handleApplicationChange}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>International Passport</p>
                <input
                  type="file"
                  onChange={(e) => {
                    setInternational_passport_file(e.target.files[0]);
                    console.log(e.target.files);
                  }}
                />
              </div>
              <div class="input_box">
                <p>Statement Of Purpose</p>
                <input
                  name="statementofpurpose"
                  type="file"
                  onChange={(e) => setStatementofpurposeFile(e.target.files[0])}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Resume</p>
                <input
                  name="resume"
                  type="file"
                  onChange={(e) => setResumeFile(e.target.files[0])}
                />
              </div>
              <div class="input_box">
                <p>O'Level Result</p>
                <input
                  name="waec_neco"
                  type="file"
                  onChange={(e) => setWaec_neco_file(e.target.files[0])}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Degree Certificate</p>
                <input
                  name="degree_certificate"
                  type="file"
                  onChange={(e) => setDegreeCertificateFile(e.target.files[0])}
                />
              </div>
              <div class="input_box">
                <p>Degree Transcript</p>
                <input
                  name="degree_transcript"
                  type="file"
                  onChange={(e) => setDegreeTranscriptFile(e.target.files[0])}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Employment Reference Letter</p>
                <input
                  name="employment_reference_letter"
                  type="file"
                  onChange={(e) =>
                    setEmploymentReferenceLetterFile(e.target.files[0])
                  }
                />
              </div>
              <div class="input_box">
                <p>Academic Reference Letter</p>
                <input
                  name="academic_reference_letter"
                  type="file"
                  onChange={(e) =>
                    setAcademicReferenceLetterFile(e.target.files[0])
                  }
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Change Name Document</p>
                <input
                  name="change_name_document"
                  type="file"
                  onChange={(e) => setChangeNameDocumentFile(e.target.files[0])}
                />
              </div>
              <div class="input_box">
                <p>Birth Certificate</p>
                <input
                  name="birth_certificate"
                  type="file"
                  onChange={(e) => setBirthCertificateFile(e.target.files[0])}
                />
              </div>
            </div>
            {isLoading ? (
              <div class="per_button">
                {/* <button>Apply</button> */}
                <button>
                  <img src={loadingAnimation} alt="loading" height={18} />
                </button>
              </div>
            ) : (
              <div class="per_button">
                <button onClick={createApplication}>Apply</button>
              </div>
            )}
          </div>
          <div class="profile_cont">
            <div class="profile_head">
              <h4>IMMIGRATION VISA FORM</h4>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Full Name</p>
                <input
                  name="name"
                  type="text"
                  onChange={handleImmigrationChange}
                />
              </div>
              <div class="input_box">
                <p>Nationality</p>
                <input
                  name="nationality"
                  type="text"
                  onChange={handleImmigrationChange}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Email Address</p>
                <input
                  name="email"
                  type="email"
                  onChange={handleImmigrationChange}
                />
              </div>
              <div class="input_box">
                <p>Mobile Number</p>
                <input
                  name="phone"
                  type="text"
                  onChange={handleImmigrationChange}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Skill Specialization</p>
                <input
                  name="skillSpecialization"
                  type="text"
                  onChange={handleImmigrationChange}
                />
              </div>
              <div class="input_box">
                <p>Destination Country</p>
                <input
                  name="nationality"
                  type="text"
                  onChange={handleImmigrationChange}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Education History</p>
                <input
                  name="educationHistory"
                  type="text"
                  onChange={handleImmigrationChange}
                />
              </div>
              <div class="input_box">
                <p>Purpose of Travel</p>
                <input
                  name="purposeOfTravel"
                  type="text"
                  onChange={handleImmigrationChange}
                />
              </div>
            </div>
            <div class="profile_box">
              <div class="input_box">
                <p>Employment History</p>
                <input
                  name="employmentHistory"
                  type="text"
                  onChange={handleImmigrationChange}
                />
              </div>
              <div class="input_box">
                <p>Note</p>
                <input
                  name="note"
                  type="text"
                  onChange={handleImmigrationChange}
                />
              </div>
            </div>

            <div class="per_button">
              <button onClick={createImmigration}>Apply</button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Apply;
